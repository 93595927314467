import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "email", "error" ]

  enterPressed(event) {
    if (event.keyCode == 13) {
      this.submit()
    }
  }

  submit() {
    if (this.emailTarget.value !== "") {
        window.location.replace('/login?email=' + this.emailTarget.value);
    } else {
        this.errorTarget.textContent = `Syötä sähköpostiosoite`
        this.emailTarget.focus();
    }
  }
}
