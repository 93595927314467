import { ValidationController } from "./validation_controller"

export default class extends ValidationController {
    // Read more about targets in the Stimulus reference guide: https://stimulusjs.org/reference/targets
    static targets = ["email", "firstName", "lastName", "phoneNumber","companyName", "companyCode", "companyContact",
        "companyStreet", "companyCity", "companyPostalCode", "kkMembership", "otherStakeholder", "tab1", "tab2", "tab3",
        "selectedBusiness", "businessSelector", "joinMembershipPlaceholder", "otherStakeholderPlaceholder", "submitButton"]
    //static values = { index: Number }

    static values = {businesses: Array}

    static rules = {
        email: { presence: { allowEmpty: false },
                 email: true,
                 length: {minimum: 3, maximum: 100, message:"^Sähköposti on liian pitkä tai lyhyt"}
        },
        firstName: { length: {minimum: 2, maximum: 100, message:"^Etunimi on liian pitkä tai lyhyt"}},
        lastName: { length: {minimum: 2, maximum: 100, message:"^Sukunimi on liian pitkä tai lyhyt"}},
        phoneNumber: { length: {minimum: 2, maximum: 100, message:"^Puhelinnumero on liian pitkä tai lyhyt"}},
        companyName: { presence: { allowEmpty: true },length: {allowEmpty:true,minimum: 4, maximum: 100, message:"^Yrityksen nimi on liian pitkä tai lyhyt"}},
        companyCode: { presence: { allowEmpty: true }, length: {allowEmpty:true, minimum: 9, maximum: 9, message:"^Y tunnus on väärässä muodossa"}},
        companyStreet: { presence: { allowEmpty: true }, length: {allowEmpty:true, minimum: 4, maximum: 100, message:"^Osoite on liian pitkä tai lyhyt"}},
        companyCity: { presence: { allowEmpty: true }, length: {allowEmpty:true, minimum: 2, maximum: 100, message:"^Kaupunki on liian pitkä tai lyhyt"}},
        companyPostalCode: { presence: { allowEmpty: true}, length: {allowEmpty:true, minimum: 4, maximum: 10, message:"^Postinumero on liian pitkä tai lyhyt"}}
    }

    static validators = {checkPhone: { attributes: ['phoneNumber' ]  }}

    checkPhone({attr, value}) {
        if(/^\+?[1-9]\d{1,14}$/.test(value) == false) {
            this.errors.add(attr, 'Puhelinnumero pitää olla kansainvälisessä muodossa(+358xxxxxxxxx)')
        }
    }

    // The connect lifecycle method runs each time our controller is connected to the DOM
    // Read more about lifecycle methods in the Stimulus reference guide: https://stimulusjs.org/reference/lifecycle-callbacks
    connect() {
        console.log("We're connected!")
        super.connect()
    }

    initialize() {
        super.initialize()
    }

    spin(event) {
        this.submitButtonTarget.classList.add("animate-spin")
        this.submitButtonTarget.classList.remove("hidden")
        document.getElementById('buttonText').classList.add("hidden")
    }

    submitok(event) {
        let [data, status, xhr] = event.detail

        if(status==200) {
            document.getElementById('notifications').insertAdjacentHTML("afterBegin", xhr.response)
        } else
        {
            location.href = response.redirectUrl
        }


    }

    updateCompanyFields(company, force_update=true) {

        if(Array.isArray(company)) {
            this.businessesValue = company
            this.updateCompanyFields(company[0])

            this.businessSelectorTarget.classList.remove("hidden")

            var select = this.selectedBusinessTarget
            var options = company

            for(var i = 0; i < options.length; i++) {
                var opt = options[i];
                var el = document.createElement("option");
                if(opt.address.street) {
                    el.textContent = opt.address.street + " " + opt.address.postCode + " " + opt.address.city;
                    el.value = i;
                    select.appendChild(el);
                }
            }
        }
        else {
            if(force_update || !this.companyCodeTarget.value) { this.companyCodeTarget.value = company.company_code }
            if(force_update || !this.companyNameTarget.value) { this.companyNameTarget.value = company.company_name }
            if(force_update || !this.companyContactTarget.value) { this.companyContactTarget.value = company.company_contact }
            if(force_update || !this.companyStreetTarget.value) { this.companyStreetTarget.value = company.address.street }
            if(force_update || !this.companyCityTarget.value) { this.companyCityTarget.value = company.address.city }
            if(force_update || !this.companyPostalCodeTarget.value) { this.companyPostalCodeTarget.value = company.address.postCode }
            if(force_update || !this.kkMembershipTarget.value) { this.kkMembershipTarget.value = company.kk_membership }
            if(force_update || !this.otherStakeholderTarget.value) { this.otherStakeholderTarget.value = company.other_stakeholder }
        }
    }

    selectBusiness() {
        this.updateCompanyFields(this.businessesValue[this.selectedBusinessTarget.value])
    }

    membershipSelected(event) {
        if(this.kkMembershipTarget.value == "") {
            this.joinMembershipPlaceholderTarget.classList.remove("hidden")
            this.otherStakeholderPlaceholderTarget.classList.remove("hidden")
        }
        else {
            this.joinMembershipPlaceholderTarget.classList.add("hidden")
            this.otherStakeholderPlaceholderTarget.classList.add("hidden")
        }
    }

    checkBusiness(event) {

        this.errors.clear()
        this.validate(event)

        if(this.companyCodeTarget.value && !this.errors.hasAny()) {

            this.businessesValue = []
            fetch("/registrations/check_company_code/"+this.companyCodeTarget.value)
                .then(response => response.json())
                .then(json => this.updateCompanyFields(json))
        }
    }

    afterValidate({ el, attr }) {
        if(this.errorMessage(attr) ) {
            this.errorMessageEl(el).textContent = this.errorMessage(attr)
            this.errorMessageCnt(el).classList.remove('hidden')
            window.scrollTo(0, 0)
            if (el.id == "registration_email") {
                el.readOnly = false;
            }
        }
        else
        {
            this.errorMessageCnt(el).classList.add('hidden')
        }

    }

    afterValidateAll(event) {
        console.log("after validate all")
        if(this.errors.hasAny() == false){ this.spin() }
    }

    errorMessageCnt(el) {
        return el.closest(".field").querySelector(".error-container")
    }

    errorMessageEl(el) {
        return el.closest(".field").querySelector(".error-message")
    }

    errorMessage(attr) {
        return this.errors.has(attr) ? this.errors.get(attr)[0] : ""
    }

}
